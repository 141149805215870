// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { collection, onSnapshot, query, where, doc, limit} from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pedidos, setPedidos] = useState('orders');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);


  const [ordersCollection, setOrdersCollection] = useState([]);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startOrdersListener(setOrdersCollection);
      return () => unsubscribe();
    }
  }, [currentUser]);

  const startOrdersListener = (setOrdersCollection) => {

    const now = new Date();

    // Definir a data inicial para meia-noite do dia atual
    const startDate = new Date(now);
    startDate.setHours(0, 0, 0, 0);

    // Definir a data final para um minuto antes da meia-noite do próximo dia
    const endDate = new Date(now);
    endDate.setHours(23, 59, 59, 999);
    
    const ordersListener = onSnapshot(
      query(
        collection(db, 'orders'),
        where('order.orderDate', '>=', startDate),
        where('order.orderDate', '<=', endDate),
        where('company.uid', '==', currentUser.uid), 
        limit(10)
      ),
      (snapshot) => {
        const ordersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrdersCollection(ordersData);
      });
    return ordersListener;
  };


  const [companiesCollection, setCompaniesCollection] = useState();

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startcompaniesListener(setCompaniesCollection);
      return () => unsubscribe();
    }
  }, [currentUser]);

  const startcompaniesListener = (setCompaniesCollection) => {
    const companiesRef = doc(db, "companies", currentUser.uid);

    const companiesListener = onSnapshot(companiesRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setCompaniesCollection com os dados do documento
        setCompaniesCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setCompaniesCollection como null ou um valor padrão, conforme necessário
        setCompaniesCollection(null);
      }
    });

    return companiesListener;
  };


  const [statusGeral, setStatusGeral] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startStatusGeralListener(setStatusGeral);
      return () => unsubscribe();
    }
  }, [currentUser]);
  
  const startStatusGeralListener = (setStatusGeral) => {
    const documentoRef = doc(db, 'statusGeral', '7aR3cmTSMUYkM9mcIRaD');
   // const documentoRef = doc(db, 'statusGeral2', 'HoX5CZjstwi1RnArV6bZ');

    const statusGeralListener = onSnapshot(documentoRef, (doc) => {
      if (doc.exists()) {
        setStatusGeral(doc.data());
      } else {
        console.log("Documento não encontrado");
      }
    }, (error) => {
      console.error("Erro em onSnapshot:", error);
    });
  
    return statusGeralListener;
  };
    



  const [configurationsCollection, setconfigurationsCollection] = useState();

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = startconfigurationsListener(setconfigurationsCollection);
      return () => unsubscribe();
    }
  }, [currentUser]);

  const startconfigurationsListener = (setconfigurationsCollection) => {
    const configurationsRef = doc(db, "configurations", currentUser.uid);

    const configurationsListener = onSnapshot(configurationsRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        // Se o documento existir, atualize setconfigurationsCollection com os dados do documento
        setconfigurationsCollection({
          id: docSnapshot.id,
          ...docSnapshot.data(),
        });
      } else {
        // Se o documento não existir, defina setconfigurationsCollection como null ou um valor padrão, conforme necessário
        setconfigurationsCollection(null);
      }
    });

    return configurationsListener;
  };



  const value = {
    currentUser,
    ordersCollection,
    companiesCollection,
    configurationsCollection,
    statusGeral,
    pedidos
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
